import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import contactus_sideimg from '../../assets/images/contactus_sideimg.png'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
import { rest } from 'lodash';

const TermsConditionsContent = () => {


    return (



        <Grid container spacing={2} className="container_topspace">
            
            <Grid item md={12} xs={12} style={{}}>

                <div className="full_contentholder">
                    <h1>Terms and Conditions for JM Limousine Pte Ltd</h1>
                    <h2 className="row_title">Introduction</h2>
                    <ul className="row_list">
                        <li>Welcome to JM Limousine Pte Ltd. By booking our services, you agree to comply with and be bound by the following terms and conditions of use.</li>
                    </ul>
                    <h2 className="row_title">User Accounts</h2>
                    <ul className="row_list">
                        <li>Users must register for an account to make bookings.</li>
                        <li>Account holders are responsible for maintaining the confidentiality of their account and password and for all activities that occur under their account.</li>
                    </ul>
                    <h2 className="row_title">Booking and Reservations</h2>
                    <ul className="row_list">
                        <li>Bookings can be made through our website or mobile app.</li>
                        <li>A deposit is required to secure a booking.</li>
                        <li>Cancellations can be made up to 24 hours before the scheduled service for a full refund.</li>
                    </ul>
                    {/* <h2 className="row_title">Pricing and Payments</h2>
                    <ul className="row_list">

                        <li>Prices are displayed on our website and are subject to change without notice.</li>
                        <li>We accept payments through credit card, debit card, and other digital payment methods.</li>
                        <li>All prices include applicable taxes.</li>

                    </ul>
                    <h2 className="row_title">Service Delivery</h2>


                    <ul className="row_list">

                        <li>Our services include airport transfers, hourly hires, and transfers for special occasions.</li>
                        <li>Details of the service options, vehicle types, and limitations will be provided at the time of booking.</li>


                    </ul>


                    <h2 className="row_title">User Conduct</h2>

                    <ul className="row_list">

                        <li>Smoking is strictly prohibited in all our vehicles.</li>
                        <li>Users must comply with all local laws and regulations during the use of our services.</li>


                    </ul>

                    <h2 className="row_title">Liability and Indemnification</h2>
                    <ul className="row_list">
                        <li>JM Limousine Pte Ltd is not liable for any accidents, delays, or damages arising from unforeseeable circumstances.</li>
                        <li>Users agree to indemnify JM Limousine Pte Ltd against any claims arising from their misuse of the service.</li>

                    </ul>
                    <h2 className="row_title"> Intellectual Property</h2>
                    <ul className="row_list">
                        <li>All content on our website is owned by JM Limousine Pte Ltd or its licensors.</li>
                        <li>Unauthorized use of our trademarks or copyrighted material is prohibited.</li>

                    </ul>
                    <h2 className="row_title">Dispute Resolution</h2>
                    <ul className="row_list">
                        <li>Disputes will be handled through mediation in the first instance.</li>
                        <li>This agreement is governed by the laws of Singapore.</li>

                    </ul>
                    <h2 className="row_title">Amendments and Termination</h2>
                    <ul className="row_list">
                        <li>JM Limousine Pte Ltd reserves the right to amend these terms at any time.</li>
                        <li>Services may be terminated for users who violate these terms.</li>

                    </ul> */}
                </div>

            </Grid>


        </Grid >




    )
}

export default TermsConditionsContent
